.blockchain-proof {
  display: block;

  thead {
    display: table;
    table-layout: fixed;
    width: 100%;

    th {
      table-layout: fixed;
    }
  }

  tbody {
    max-height: 500px;
    display: block;

    tr {
      display: table;
      table-layout: fixed;

      td {
        text-overflow: ellipsis;
        overflow: hidden;
        width: 160px;
        height: 1.2em;
        white-space: nowrap;
      }
    }
  }
}


/* You can add global styles to this file, and also import other style files */
//  Pink styling
.pink-background {
  background-color: #e5007d;
}

.black-background {
  background-color: #000000;
}

.text-pink {
  color: #e5007d;
}

.text-grey {
  color: #434343;
}

.back-grey {
  background-color: #242424;
}

.ul {
  padding: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: unset !important;
}

.spacing {
  height: 55px;
}

//  Fonts
@font-face {
  font-family: AlternateGotNo3D;
  src: url(/assets/global/fonts/AlternateGotNo3D.ttf);
}

@font-face {
  font-family: Roboto;
  src: url(/assets/global/fonts/RobotoCondensed-Regular.ttf);
}

body {
  min-width: 300px;
}

//AGM Maps
agm-map {
  height: 300px;
  width: 100%;
}

.markerFarmer {
  position: relative;
  border-radius: 100%;
  width: 50px;
  height: 51px;
  left: -14.1439px;
  top: -91.3825px;
}

#headerMap {
  [aria-label="Map"] {
    & > div:last-child {
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(246, 246, 246, 0.5) 47%,
        rgb(255, 255, 255) 100%
      );
    }
  }
}
.headerFarmer {
    position: relative;
    border-radius: 100%;
    width: 80px;
    height: 80px;
    border: 4px solid #e5007d;
    z-index: 1;
    box-shadow: 0 25px 40px -1px grey;
  }
  .headerIcon {
    left: 95px;
    top: -30px;
    position: relative;
    z-index: 2;
    width: 40px;
    height: 40px;
  }

  @media screen and (max-width: 576px) {
    body {
      padding-bottom: 85px;
    }
  }